.sub {
  margin-top: 1em;
  margin-right: .5em;

  .top-sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .5em;

    &:hover {
      box-shadow: 0 0 2.5px .1px var(--main-color);
    }

    &.selected {
      background-color: var(--main-color);

      .input-sub-text {
        background-color: var(--main-color);
        color: #fff !important;
      }

      .sub-option-icon {
        filter: invert(40%) brightness(200%);
      }
    }

    &:not(.confirmed) {
      .sub-option-icon, .color-picker{
        pointer-events: none;
        opacity: .7;
      }
    }

    .sub-option-container {
      display: flex;
      gap: .5em;

      .color-picker {
        width: 2em;
        outline: none;
        appearance: none;
        background-color: transparent;
        border: none;
        cursor: pointer;
        height: 2em;
      }

      .sub-icon {
        padding: .1em;
        border-radius: .3em;
        cursor: pointer;
        position: relative;
      }

      .sub-text {
        font-weight: bold;
        font-size: 1.3em;
        font-style: italic;
        text-transform: uppercase;
        min-width: 7.5em;
        margin-right: 1em;
        cursor: default;

        &.active {
          color: white;
        }


        &.input-sub-text {
          border: none;
          margin: 0;
          outline: none;
          font-size: 1.1em;
          color: var(--main-color);
          max-width: 180px;

          &::placeholder {
            color: white;
          }

          &:read-only {
            pointer-events: none;
          }
        }
      }
    }

    .sub-option {
      display: flex;
      gap: .5em;
      flex: .2;
      margin-right: .5em;

      .sub-option-item {
        cursor: pointer;

        &:active {
          transform: scale(.9);
        }
      }

      .sub-option-icon {
        cursor: pointer;
        width: 1.4em;
        height: 1.4em;
        background-color: var(--main-color);
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center;
        mask-position: center;
        &.copy {
          -webkit-mask-image: url(../../../../wcassets/icons/copy.svg);
          mask-image: url(../../../../wcassets/icons/copy.svg);
        }

        &.delete {
          -webkit-mask-image: url(../../../../wcassets/icons/remove.svg);
          mask-image: url(../../../../wcassets/icons/remove.svg);
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .edit-categories {
    margin-block: .5em;

    .rubriques {
      display: flex;
      justify-content: space-between;
      margin-right: .5em;
      gap: .5em;
      
      .rubrique-item {
        padding: .2em .5em;
        cursor: pointer;
        border: 1px solid black;
        border-radius: .3em;
        font-size: .8em;
        &.active {
          background-color: var(--main-color);
          color: white;
          font-weight: bold;
          font-style: italic;
          border: none;
  
        }
      }

    }
  }
}