@media (max-width: $break-md - 1){
  // *** index.php *** //
  .image-index {
    &.creation-compte-form {
      height: 99px;
    }
  }
  .content {
    &.creation-compte-form {
      form {
        margin-top: 52px;

        .form-part {
          margin-bottom: 60px;

          .row {
            background-color: rgba(199, 205, 213, 0.2);
          }
        }
        .rows-wrap {
          background-color: unset;
        }
      }
    }
  }
}