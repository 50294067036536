@import "../../../wcassets/styles/colors.scss";

.SideMenu{
  position: absolute;
  left: 10px;
  top: 100px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  .menu-item{
    .menu-button{
      user-select: none;
      outline: none;
      text-align: center;
      border: 0.5px solid #A4A4A4;
      backdrop-filter: blur(5px);
      background-color: rgba(#fff, .5);
      border-radius: 10px;
      box-shadow: 1px 1px 1px #a4a4a4;
      box-sizing: content-box;
      width: 75px;
      height: 75px;
      display: flex;
      flex-direction: column;
      background-size: 0;
      background-repeat: no-repeat;
      justify-content: center;
      transition: .2s ease;
      cursor: pointer;
      &.adds{ background-image: url(../../../wcassets/icons/adds.png);}
      &.finitions{ background-image: url(../../../wcassets/icons/finitions.png);}
      &.models{ background-image: url(../../../wcassets/icons/models.png);}
      &.spaces{
        background-image: url(../../../wcassets/icons/sol.png);
        &:before{
          background-size: 85%;
          opacity: .6;
        }
      }
      span{
        text-transform: uppercase;
        font-size: .75em;
        align-self: center;
        text-align: center;
      }
      &:before{
        content: '';
        width: 50px;
        height: 50px;
        background-image: inherit;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        align-self: center;
      }
      &:hover{
        background-color: #fad4ed; // E788C5
        box-shadow: 0 0 0 2px $main;
        transform: scale(1.03);
      }
    }
    .SidePane{
      transform: translate3d(0, 1em, 0);
      transition: .2s ease;
      visibility: hidden;
      opacity: 0;
    }
    &:focus-within{
      .menu-button{
        background-color: #fad4ed; // E788C5
        box-shadow: 0 0 0 2px #E788C5;
        transform: scale(1.03);
      }
      .SidePane{
        transform: none;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}