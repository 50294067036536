@media (min-width: $break-lg){
  // *** Général *** //
  .black-card {
    .card-btn {
      margin-top: 25px;
    }
  }
  .bouton-simple {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 124px;
      height: 1px;
      background-color: #cfd4dc;
      border-right: 10px solid #fff;
      border-left: 10px solid #fff;
      bottom: -1px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .slider_container {
    padding: 0 43px;
    .slider_base {
      .slide-text {
        background: url("../../images/slider-large.svg") no-repeat;
        padding: 27px 95px 23px 33px;
        height: 212px;
        max-width: 713px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        .slide-titre {
          overflow: auto;
          height: auto;
          font-size: 20px;
          font-weight: 700;
          line-height: 27px;
          margin-bottom: 14px;
        }
        .slide-content {
          display: block;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          max-height: 78px;
          overflow: hidden;
        }
      }

      .slick-slide {
        position: relative;
        height: 335px;

        img:not(.pastille) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          width: 100%;
        }
      }

      .slick-arrow{
        z-index: 1;
        position: absolute;
        font-size: 40px;
        font-weight: 400;
        color: $pink;
        bottom: 136px;
        &.left{
          left: -43px;
        }

        &.right{
          right: -43px;
        }
      }
    }
  }

  .ariane {
    display: block;
  }

  // *** Header *** //
  #header-btn {
    text-align: left;
  }

  #header-black {
    height: 87px;

    .container {
      position: relative;
      height: 100%;
    }

    .logo {
      margin-left: 31px;
      a {
        display: flex;
      }
      img{
        margin-top: 0;
        width: 182px;
        height: 31px;
      }
      span {
        color: #ffffff;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.26px;
        line-height: 14px;
        text-transform: uppercase;
        margin-left: 17px;
        margin-top: 4px;
      }
    }

    .profil {
      a {
        .infos {
          .id {
            color: #fff;
          }
          .compte {
            color: #fff;
          }
        }
      }
    }

    .menu-rapide {
      .phone-menu-down {
        margin-left: 50px;

        .phone {
          display: flex;
        }

        img {
          margin-right: 15px;
        }

        .menu-down {
          margin: 0 16px 0 15px;
        }
      }
    }
  }

  #main_menu {
    display: block;
    position: absolute;
    ul {
      padding: 0;
      margin: 0;
      li {
        padding: 0;
        margin: 0;
        a {
          span {
            display: none;
          }
        }
      }
    }

    &.fermer {
      width: 81px;
      ul {
        li {
          text-align: center;
          a {
            width: 35px;
            position: relative;
            span {
              display: none;
            }
            i, img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateY(-50%) translateX(-50%);
            }
          }
        }
      }
    }

  }

  #secondary_menu {
    margin-top: 0;
    right: 0;
    top: 71px;
  }

  .profil {
    margin-right: 31px;
    margin-top: 9px;
    padding-bottom: 0;
  }

  // *** index.php *** //
  .content {
    .lost-password {
      padding-bottom: 0;
    }
    form {
      .icheckbox {
        margin: 1px 13px 0 -2px;
      }
    }
    .container {
      .row {
        justify-content: unset;
        align-items: unset;

        .logo-title {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      }
    }
    .logo-wrap {
      margin-bottom: 55px;
      margin-top: 203px;
    }
    h2 {
      font-size: 35px;
    }
    &.creation-compte {
      .form-wrap {
        margin-top: 121px;
      }
      form {
        margin-top: 39px;
      }
      .new-account {
        display: inline-block;
        position: absolute;
        right: 15px;
        width: 278px;
        padding: 8px 13px;
        font-size: 15px;
        margin-top: 25px;
        a {
          font-size: 15px;
          i {
            &.right {
              right: 18px;
              margin: 0;
            }
          }
        }
      }
    }

    &.creation-compte-form {
      .input_text {
        &.large {
          margin-bottom: 22px;
        }
      }
    }
  }
}

@media (min-width: $break-lg) and (max-width: $break-xl){

}