@import "../../../../wcassets/styles/colors.scss";

.TreeData{
  padding-left: 10px;
  .mini-title{
    font-size: .8em;
    margin-left: 1em;
    margin-bottom: -.3em;
    display: flex;
    color: darken($main, 10%);
    text-transform: capitalize;
  }
}