@import "../../../../wcassets/styles/colors.scss";

.Finitions{
  padding: 10px;
  .acc-title{
    padding: 5px 0;
  }
  .disclaimer{
    font-size: .7em;
    top: 0;
    left: 0;
    right: 0;
    background-color: $main;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    align-self: center;
    line-height: 1;
    padding: 3px 10px;
    margin-bottom: 1em;
    position: relative;
    z-index: 1000;
  }
  &.is-active{
    .disclaimer{
      display: none;
    }
  }
  &:not(.is-active){
    .Accordion{
      opacity: .5;
      pointer-events: none;
    }
  }
}