.PriceInput {
  user-select: none;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 3.5em;
  margin: 10px;
  align-items: flex-start;
  //pointer-events: none;
  .input-field {
    overflow: hidden;
    position: relative;
    border-radius: .3em;
    background: rgba(#fff, .5);
    overflow: hidden;
    height: 2.2em;
    width: 100%;
    input{
      font-size: 1.1em;
      border: none;
      padding: .5em;
      padding-right: 2.5em;
      text-align: right;
      outline: none;
      background: none;
      position: absolute;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
    .reload{
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 2em;
      height: 100%;
      cursor: pointer;
      pointer-events: initial;
      background-color: rgba(#fff, .4);
      border-radius: .3em;
      transition: .1s ease;
      &:before{
        content: '';
        position: absolute;
        background-image: url(../../../wcassets/icons/reload.svg);
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: center;
        inset: 0;
      }
      &.loading:before{
        animation: rotation 2s infinite linear;
      }
    }
    &:hover:before{
      background-size: 75%;
    }
    &:active:before{
      background-size: 60%;
      transition-duration: 0s;
    }
  }
  span {
    font-size: .8em;
    margin-bottom: .3em;
  }

  .sizer{
    font-size: 1.1em;
    padding: 0 3em 0 .5em;
    text-align: right;
    max-height: 0;
    overflow: hidden;
  }
}

@keyframes rotation {
  from { transform: rotate(0deg) } to { transform: rotate(-359deg) }
}
