.Spaces {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  flex: 1;
  position: absolute;
  width: 300px;
  top: 40px;
  inset: 0;
  left: auto;
  background: #fff;
  z-index: 3;


  .title {
    margin: 1.5em 1em 0 1em;
    flex: .1;

    display: flex;
    gap: .5em;
    justify-content: start;
    align-items: center;


    .title-text {
      color: var(--main-color);
      font-style: italic;
      font-size: 1.5em;
      font-weight: bold;
    }

  }

  .sub-wrapper {
    flex: 1 0 0;
    // max-height: 90%;
    overflow: auto;
    position: relative;
    display: flex;

    .space-scrollbar {
      flex: 1;
      padding-inline: 1em 0;
    }
    

    .add-volume {
      margin-block: 2em;
      flex: 1;

      .add-title {
        display: flex;
        align-items: center;
        gap: .5em;

        .add-icon {
          font-size: 1.2em;
          padding: .7em;
          background-image: url(../../../wcassets/icons/plus.svg);
          background-color: var(--main-color);
          background-position: center;
          background-size: 60%;
          background-repeat: no-repeat;
          border-radius: .3em;
          cursor: pointer;

        }

        .add-text {
          font-size: 1.1em;
          color: darkgrey;
          cursor: pointer;
        }
      }
    }
  }
}


.icon-set-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 1em;
  height: 1em;
}