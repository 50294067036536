.Disconnected{
  border-radius: 5px;
  padding: 20px 30px;
  z-index: 2;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  background-color: rgba(#fff, .4);
  box-shadow: 1px 1px 2px #A4A4A4;
  min-width: 250px;
  &:before{
    content: "";
    width: 5em;
    height: 3em;
    background-image: var(--main-logo);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  h3 {
    margin: 0;
    color: var(--main-color);
  }
  .disconnected-btns {
    display: flex;
    gap: 1em;

    .di-btn {
      background-color: var(--main-color);
      color: white;
      padding: .3em .5em;
      cursor: pointer;
      user-select: none;
    }
  }
}
.DisconnectedContainer{
  position: fixed;
  inset: 0;
  z-index: 1000;
  position: fixed;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  &.closed{
    pointer-events: none;
    backdrop-filter: none;
    transition: .5s ease;
    .OpenWork{
      transition: .5s ease;
      opacity: 0;
    }
  }
}