.Vue{
  .selections{
    min-width: 200px;
    display: flex;
    flex-direction: column;
    .selection{
      display: flex;
      align-items: center;
      gap: .5em;
      white-space: nowrap;
      font-size: .9em;
      padding: 0 1em;
      padding-left: .5em;
      cursor: pointer;
      text-align: left;
      &:before{
        content: '';
        width: 35px;
        height: 35px;
        background-image: url(../../../../wcassets/icons/models.png);
        background-position: center;
        background-size: 90%;
        background-repeat: no-repeat;
      }
      &:hover{
        background-color: rgba(rgb(237, 50, 187), .25); // E788C5
      }
    }
  }
}