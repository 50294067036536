.HeadOption{
  background-color: rgba(#fff, .3);
  position: absolute;
  top: 100%;
  margin-top: 10px;
  margin-left: 2em;
  z-index: 2;
  box-shadow: 1px 1px 2px #555;
  border-radius: 5px;
  overflow: hidden;
  transition: .2s ease;
  transform: translate3d(-50%, 0, 0);
  backdrop-filter: blur(5px);
  .ho-title{
    background-color: #424242;
    color: white;
    padding: 2px;
    text-align: center;
    text-transform: uppercase;
    padding: .5em 0;
    white-space: nowrap;
  }
  &:not(.shown){
    transform: translate3d(-50%, 10px, 0);
    opacity: 0;
    visibility: hidden;
  }
}