.Title-Header {
  display: flex;
  background-color: var(--main-color);
  padding: .4em .8em;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1.1em;

  .header-part {
    display: flex;
    align-items: center;
    gap: .3em;
    .close {
      width: 1em;
      height: 1em;
      font-size: 20px;
      background-color: var(--main-color);
      border-radius: 100%;
      background-image: url(../../../../wcassets/icons/close.svg);
      background-size: 80%;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      transition: .9s ease;
      border: 1px solid white;


      &:hover {
        transform: scale(1.1);
        transform: rotate(90deg);
      }
    }
    .header-text {
      color: white;
      padding-left: .5em;
    }
  }

  .header-icon {
    width: 2em;
    height: 1.5em;
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(100%);
  }
}