
.Properties {
  position: absolute;
  // background-color: black;
  inset: 0;
  left: auto;
  width: 300px;
  z-index: 20;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 2em;

  .menu-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;

    .sub-wrapper {
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      gap: 1.5em;
      overflow: hidden;

      .scroll-area {
        padding: 0 .8em;
        

        .sub-part {
          // .part-title {
          //   font-weight: bolder;
          //   font-size: 1.5em;
          //   color: var(--main-color);
          //   filter: brightness(80%);
          //   width: 100%;
          //   border-bottom: 2px solid var(--main-color);
          //   margin-bottom: .5em;
          // }

          .sub-property {
            display: flex;
            flex-direction: column;
            gap: .3em;
            // margin-bottom: .8em;
            padding-bottom: 1em;

            .sub-text {
              color: rgb(160, 158, 158);
              font-weight: 600;
              font-size: .9em;
              font-style: italic;
              color: var(--main-color);
              filter: brightness(80%);

              .sub-text-detail {
                font-size: .9em;
              }
            }

            .inputs-parent {
              display: flex;
              gap: .5em;

              .values-wrapper {
                display: flex;
                flex-wrap: wrap;
                gap: 1em;
                // width: 100%;   

              }

              .inputs {
                width: 100%;
                padding: .4em;
                border-radius: .3em;
                border: 1px solid lightgrey;
                // text-align: s;
                outline: none;
              }
            }
          }
        }
      }


    }
  }



}