.ContextMenu {
  text-align: center;
  top: var(--y, '90px');
  left: var(--x, '90px');
  position: fixed;
  border-radius: 5px;
  width: fit-content;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  font-size: .9em;
  box-shadow: 1px 1px 2px #333;
  overflow: hidden;
  outline: none;
  transition: opacity .2s ease;
  &:not(:focus-within){
    opacity: 0;
    pointer-events: none;
  }
  &.waiting{
    .context-item{
      display: none;
    }
    &:before{
      content: '';
      height: 3em;
      width: 10em;
      background-image: url(../../../wcassets/icons/load.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 30%;
      filter: drop-shadow(0 0 1px #fff) drop-shadow(0 0 0 #fff);
    }
  }
  .context-item {
    display: flex;
    justify-content: center;
    line-height: 1.2;
    position: relative;
    padding: .5em 2em;
    background-color: transparent;
    color: #333;
    cursor: pointer;
    span{
      transition: .1s ease;
    }
    &:hover{
      span{
        transform: scale(1.1);
        text-shadow: 0 0 .5px #333;
      }
    } 
    &:not(:last-child)::after{
      content: '';
      position: absolute;
      bottom: 0;
      width: 80%;
      left: 10%;
      height: 1px;
      background-color: rgba(#000, .1);
    }
  }
}

.image{
  outline: none;
}

.image:focus-within ~ .box, .box:focus-within{
  display: initial;
  outline: none;
}