.SaveWork{
  position: fixed;
  top: 60px;
  right: 20px;
  border-radius: 5px;
  padding: 20px;
  z-index: 2;
  display: flex;
  gap: 10px;
  flex-direction: column;
  background-color: rgba(#fff, .5);
  backdrop-filter: blur(5px);
  box-shadow: 1px 1px 2px #A4A4A4;
  background-color: #fff;

  .buttons{
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-end;
    .button {
      background-color: var(--main-color);
      padding: .5em 1.5em;
      border: none;
      float: right;
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
      transition: .1s ease;
      text-align: center;
      &:hover{
        transform: scale(1.05);
      }
      &:active{
        transform: scale(.95);
        transition-duration: 0;
      }
      &.local{
        transition: .1s ease;
        font-size: .75em;
        &:hover{
          transform: scale(0.9);
        }
        input{
          display: none;
        }
      }
    }
    .hr{
      background-color: var(--main-color);
      align-self: center;
      height: 1px;
      width: 50%;
    }
  }
  label{
    display: flex;
    flex-direction: column;
    span{
      font-size: .8em;
      margin-bottom: .3em;
      color: #555;
    }
    input{
      outline: none;
      border: none;
      width: 200px;
      padding: 0.8em 1em;
      background-color: rgba(#fff, .5);
      box-shadow: 0 1px 2px #A4A4A4;
    }
  }
}

