.bool-part {
  display: flex;
  justify-content: center;
  flex: 1;

  .bp-item {
    width: 100%;
    text-align: center;
    border: .5px solid rgb(160, 158, 158);
    cursor: pointer;
    transition: background-color .2s ease;

    &.active {
      color: white;
      background-color: var(--main-color);
      border: none;
    }

    &:first-child {
      border-radius: .5em 0 0 .5em;
    }

    &:last-child {
      border-radius: 0 .5em .5em 0;
    }
  }
}