@import "../../../../../wcassets/styles/colors.scss";

.About{
  display: flex;
  line-height: 1.3;
  &:before{
    content: '';
    width: 13em;
    background-image: url(../../../../../wcassets/icons/wd-back.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 100% 100%;
  }
  .right{
    text-align: right;
    white-space: nowrap;
    font-size: .8em;
    padding: 1em;
    .wd{
      height: 3.3em;
      background-image: url(../../../../../wcassets/icons/wd.png);
      background-size: contain;
      background-position: 100% 50%;
      background-repeat: no-repeat;
      margin-top: 2em;
    }
    .abt{
      margin-top: 2em;
      
    }
    .abt, .abt ~ div{
      font-size: .8em;

    }
    a{
      all: unset;
      cursor: pointer;
      &:hover{
        color: darken($main, 30%);
      }
    }
  }
}