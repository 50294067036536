.OpenWork{
  border-radius: 5px;
  padding: 20px 30px;
  z-index: 2;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  background-color: rgba(#fff, .4);
  box-shadow: 1px 1px 2px #A4A4A4;
  min-width: 250px;
  &:before{
    content: "";
    width: 3em;
    height: 1em;
    background-image: url(../../../wcassets/icons/load.svg);
    background-size: cover;
    background-position: center;
  }
  h3 {
    margin: 0;
    color: #963574;
  }
}
.OpenWorkContainer{
  position: fixed;
  inset: 0;
  z-index: 1000;
  position: fixed;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .5s ease;
  &.closed{
    pointer-events: none;
    backdrop-filter: none;
    .OpenWork{
      transition: .5s ease;
      opacity: 0;
    }
  }
}