.value-part {
  border: 1px solid lightgrey;
  padding: 0 .4em;
  cursor: pointer;
  padding: 0 .8em;
  border-radius: .3em;
  font-size: .9em;

  &.active {
    background-color: var(--main-color);
    color: white;
    font-weight: 600;
    border: none;
  }
}

