@media (min-width: $break-md) {
  // *** Général *** //
  .input_text {
    width: 100%;
  }

  .slider_container {
    .slider_base {
      .slide-text {
        .slide-titre {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
      .slick-slide {
        img {
          max-height: 328px;
        }
      }
    }
  }

  // *** index.php *** //
  .image-index {
    background: url(../../images/background-index.png) center center;
    background-size: cover;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -5;
  }

  .content {
    margin-top: 165px;
    margin-bottom: 159px;

    .lost-password {
      padding-bottom: 25px;
    }

    .container {
      height: 100%;

      .row {
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }

    .logo-wrap {
      img {
        width: 294px;
        height: 51px;
      }

      margin-bottom: 50px;
    }

    h2 {
      font-size: 30px;

      &:after {
        content: none;
      }
    }

    h1 {
      margin-top: 0;
    }

    .form-wrap {
      background-color: #ffffff;
      padding: 30px 30px;
    }

    .new-account {
      margin-top: 20px;

      i {
        &.right {
          //margin-right: 15px;
        }
      }
    }
    &.creation-compte {
      .container {
        .row {
          height: 100%;
          justify-content: center;
          align-items: center;
        }
      }

    .input_text {
        width: 100%;
        &.large {
          width: 100%;
        }
      }
      .new-account {
        padding: 18px 16px;
        position: unset;
        a {
          i {
            &.right {
              right: 15px;
            }
          }
        }
      }
    }
    &.creation-compte-form {
      margin-top: 45px;
      margin-bottom: 40px;

      h4 {
        margin: 0;
        margin-bottom: 29px;
      }

      .container {
        .row {
          height: auto;
          align-items: unset;
        }
      }
      .form-wrap {
        h1 {
          padding: 24px 24px 0;
        }
      }
      form {
        .rows-wrap {
          padding: 20px 25px;
        }

        .form-part {
          margin-bottom: 25px;
          .row {
            //margin: 0 5px;
          }
        }
        .uppercase-label {
          &.first {
            margin-top: 0px;
          }
        }
        .same-address {
          margin-bottom: 30px;
        }
      }
      .bouton {
        font-size: 16px;
      }
    }
  }


  // *** Footer *** //
  #footer_bottom{
    width: 100%;
    height: 44px;
    background-color: #ffffff;
  }
}