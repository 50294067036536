.edit-zone {
  display: flex;
  flex-direction: column;
  gap: .3em;

  .edit-item {
    display: flex;
    justify-content: space-between;
    gap: .5em;

    .item-label {
      font-weight: 600;
      font-size: 1em;


    }

    .inputs {
      position: relative;
      margin-right: .5em;

      .item-input {
        font-weight: bold;
        border: 1px solid lightgrey;
        border-radius: .3em;
        padding: .5em;
        width: 150px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;

        }
      }

      .placeholder {
        position: absolute;
        font-size: .8em;
        right: 0;
        bottom: .45em;
        margin: .2em .5em;
        color: grey;
      }
    }

  }
}