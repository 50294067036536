.Accordion{
  >.acc-title{
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    gap: .5em;
    transition: .2s ease;
    margin-bottom: .5em;
    &:before{
      content: '';
      width: .8em;
      height: .8em;
      background-image: url(../../../wcassets/icons/accordion-arrow.png);
      background-repeat: no-repeat;
      background-size: 60%;
      background-position: center;
      transition: .2s ease;
    }
  }
  >.acc-container{
    max-height: 0px;
    overflow: hidden;
    transition: max-height .1s ease;
    .acc-computer{
      width: 100%;
      height: auto;
    }
  }
  &.opened{
    >.acc-title{
      text-shadow: 0 0 1px #333;
      &:before{
        transform: rotate(90deg);
      }
    }
    >.acc-container{
      max-height: var(--max);
    }
  }
}