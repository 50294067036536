@media (min-width: $break-xl){
  // *** Général *** //
  .input_text {
    width: 350px;
  }
  .slider_container {
    margin-top: 52px;
    margin-bottom: 46px;
    .slider_base {
      .slide-text {
        .slide-titre {
          display: block;
        }
      }
    }
  }
  table{
    thead{
      font-size: 14px;
      th {
        padding: 10px 5px;
      }
    }
    tbody{
      td{
        padding: 20px 5px;
        &.number {
          font-size: 14px;
        }
        &.statut {
          font-size: 14px;
        }
        &.montant {
          font-size: 16px;
        }
      }
    }
  }
  // *** Header *** //
  #secondary_menu {
    right: 16.5%;
  }

  // *** index.php *** //
  .content {
    h2 {
      font-size: 47px;
      line-height: 43px;
    }
    .form-wrap {
      position: relative;

      h1 {
        margin-top: 0;
        margin-bottom: 3px;
      }

      .cadre-rose {
        position: absolute;
        width: 394px;
        height: 456px;
        border: 11px solid $pink;
        top: -28px;
        left: -30px;
        z-index: -1;
      }

    }
    .new-account {
      i {
        margin-left: 9px;

        &.right {
          //margin-right: 38px;
        }
      }
    }

    &.creation-compte {
      .input_text {
        width: 257px;
        margin-left: -6px;
        &.large {
          width: 351px;
          margin-left: 0;
        }
      }
    }

    &.creation-compte-form {
      .form-wrap {
        margin-top: 12px;
      }

      form {
        label {
          &.petit {
            padding: 0 43px 0 72px;

            .icheckbox {
              left: 35px;
            }
          }
        }
        .form-part {
          &.last {
            margin-bottom: 35px;
          }
        }
        .rows-wrap {
          padding: 20px 25px;
        }
        .uppercase-label {
          &.first {
            margin-top: 0;
          }

          &.large {
            margin-top: 8px;
          }
        }
        .btn-connexion {
          margin-top: 36px;
          text-align: right;
        }
      }

      .aside {
        margin: 66px 0 0;
      }
    }
  }

  .dashboard {
    padding-bottom: 71px;
    h1 {
      line-height: 60px;
      margin-bottom: 11px;
    }
    h2 {
      font-size: 30px;
      margin: 0 0 26px;
      line-height: 60px;
    }

    .wrap-content {
      margin-bottom: 30px;
      &.padding {
        padding-left: 42px;
        padding-right: 42px;
      }
    }

    .toggle {
      .texte {
        margin-bottom: 41px;
        .thumbnail {
          width: 300px;
          height: 200px;
        }
      }
    }
  }
}

@media (min-width: 1380px) {
  .slider_container {
    .slider_base {
      .slide-text {
        top: 77px;
        left: 62px;
        transform: unset;
      }
    }
  }
}