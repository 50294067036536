.text-content {
  background: #202035;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 13px;
  user-select: none;
  // min-width: 10em;
  transform: translateX(-50%);


  &.active {
    // margin-top: 1em;
    opacity: .7;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    font-size: .6em;
  }
  &:not(.active){
    display: none;
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    // padding: 5em;
  }
}