@import 'common.scss';

// *** Presets *** //
html {
    overflow-x: hidden;
}

p {
    margin: 0;
}

fieldset {
    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: auto;
    border-width: 2px;
    border-style: groove;
    border-color: threedface;
    border-image: initial;
    legend {
        display: block;
        padding-inline-start: 2px;
        padding-inline-end: 2px;
        border-width: initial;
        border-style: none;
        border-color: initial;
        border-image: initial;
        width: auto;
        font-size: inherit;
        margin-bottom: 0px;
    }
}

$main: #E788C5;

// *** Général *** //
.input_text{
  width: 333px;
  max-width: 100%;
  height: 30px;
  border-radius: 26px;
  border: 2px solid rgba(99, 111, 121, 0.25);
  background-color: #ffffff;
  color: black;
  font-size: 15px;
  padding: 0 0 0 19px;
  margin-bottom: 10px;
  box-shadow: none;
  &:invalid {
    box-shadow: none;
  }
  &:focus{
    outline: none;
    box-shadow: none;
  }

  &[disabled] {
    background: lightgrey;
  }
}

.bouton{
  display: inline-block;
  color: $pink;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0.68px;
  text-transform: uppercase;
  border: 1px solid black;
  padding: 12px 19px 11px;
  position: relative;
  background-color: white;
  transition: all 0.5s;
  &:after{
    content: " ";
    height: 12px;
    border: 5px solid white;
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
    width: 112px;
    background-color: #cfd4dc;
  }
  &:hover{
    text-decoration: none;
    background-color: $pink;
    color: white;
    border-color: $pink;
    &:after{
      border: 0;
      bottom: -3px;
      height: 4px;
      width: 112px;
    }
  }
  i{
    font-size: 19px;
    margin-left: 15px;
  }
}
.bouton-simple {
  display: inline-block;
  color: $pink;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0.68px;
  text-transform: uppercase;
  border: 1px solid #000;
  padding: 12px 19px 9px;
  position: relative;
  background-color: white;
  transition: all 0.5s;

  &:hover {
    background-color: #000;
    border: 1px solid #fff;
    color: #fff;
  }
}
.btn-outline-pink {
  border: solid 2px $pink;
  color: white;
  background-color: $pink;
  padding: .84rem 2.14rem;
  padding-top: .7rem;
  padding-bottom: .7rem;
  font-size: .81rem;
  border-radius: 0;
  text-transform: uppercase;
  &.btn-sm {
    padding: .5rem !important;
  }
  &:hover{
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
    color: white;
  }

  &.lightgrey {
    border: solid 2px #adb5bd !important;
    background-color: #adb5bd !important;
  }
  &.greyish {
    border: solid 2px #6c757d !important;
    background-color: #6c757d !important;
  }
}
.btn-outline-elegant {
  border: solid 2px #2e2e2e ;
  color: #2e2e2e;
  background-color: transparent;
  padding: .84rem 1.14rem;
  padding-top: .7rem;
  padding-bottom: .7rem;
  font-size: .81rem;
  border-radius: 0;
  text-transform: uppercase;
  &:hover{
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
    color: #212529;
  }
}
.btn-elegant {
  color: white;
  background-color: #2e2e2e;
  padding: .84rem 2.14rem;
  padding-top: .7rem;
  padding-bottom: .7rem;
  font-size: .81rem;
  border-radius: 0;
  text-transform: uppercase;
  &:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    color: white;
  }
}
.badge-primary {
  &.pink {
    background-color: #c31f7c;
  }
  &.orange {
    background-color: #fd7e14;
  }
  &.lightgrey {
    background-color: #adb5bd;
  }
  &.greyish {
    background-color: #6c757d;
  }
}

.black-card {
  background-color: #000000;
  padding: 37px 30px 26px;
  .card-title {
    color: #ffffff;
    font-size: 23px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
    border-bottom: 1px solid #fff;
    padding-bottom: 11px;
  }
  .card-content {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    margin-top: 11px;
    line-height: 1.3em;
    strong {
      font-weight: 700;
    }
    span {
      color: #eb399e;
      font-weight: 700;
    }
  }
  .card-btn {
    margin-top: 46px;
    a {
      color: $pink;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      border: 1px solid #000000;
      background-color: #ffffff;
      padding: 7px 14px;
      width: 100%;
      position: relative;
      transition: all 0.5s;

      &:hover {
        border: 1px solid #fff;
        background-color: #000;
        color: #fff;
      }

      i {
        position: absolute;
        right: 10px;
        font-size: 17px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.serviceclient {
  margin: 28px 0 52px 28px;
  position: relative;
  img {
    position: absolute;
    left: -25px;
    top: 4px;
  }
  .title-sc {
    transition: all 0.5s;
    color: #000000;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.45px;
    text-transform: uppercase;
    margin-bottom: 4px;
  }
  .numbersc {
    a {
      transition: all 0.5s;
      color: $pink;
      font-size: 22px;
      font-weight: 700;
    }
  }

  &:hover {
    .title-sc {
      color: $pink;
    }
    .numbersc {
      a {
        color: #000;
      }
    }
  }
}

table{
  width: 100%;
  thead{
    color: $pink;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-transform: uppercase;
    th{
      padding: 10px 19px;
      background-color: #f1f2f3;
      white-space: nowrap;
    }
  }
  tbody{
    color: #303030;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    background-color: #fff;
    tr:nth-child(even){
      background-color: #f1f2f3;
    }
    td{
      padding: 7px 29px;
      
      &#actions {
        i {
          font-size: 16px;
        }  
      }
      i {
        font-size: 22px;
        margin-right: 12px;
      }
      strong {
        font-weight: 700;
      }
      span:not(.pointer) {
        text-transform: uppercase;
      }
    }
  }
}

.datepicker.dropdown-menu {
    padding: 0;
    font-size: 14px;
}
.datepicker {
    table thead {
      font-size: 14px;
      line-height: 12px;
      th {
        border-radius: 0;
        padding: 5px 15px;
      }
    }
    table tbody td {
        padding: 0px;
        border-radius: 0;
    }
}

.toggle_me{
  margin: 5px 0;
  h4{
    border: 1px solid #c3c9cb;
    background-color: rgba(0, 0, 0, 0);
    margin: 0;
    padding: 13px;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
    color: $pink;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2em;
    text-transform: uppercase;
    transition: all 0.5s;
    i{
      position: absolute;
      right: 14px;
      top: 8px;
      display: inline-block;
      width: 30px;
      height: 31px;
      background-color: #e8ebee;
      border-radius: 50%;
      color: #000000;
      font-size: 19px;
      text-align: center;
      line-height: 34px;
      &.fa-angle-up{
        display: none;
      }
    }
  }
  .texte{
    display: none;
    ul {
      padding-left: 26px;
    }
  }
  .toggle {

    &:hover {
      h4 {
        background-color: $pink;
        color: white;
      }
    }

    &.active {
      h4 {
        background-color: #000;
        color: white;
        padding-left: 26px;
      }

      i.fa-angle-up {
        display: inline-block;
        background-color: white;
      }

      i.fa-angle-down {
        display: none;
      }

      .texte {
        display: flex;
      }
    }
  }
}

.menu_burger {
    .burger {
        display: inline-block;
        background-size: cover;
        background: transparent url(../images/icon-burger-white.svg) no-repeat 0 0;
        width: 45px;
        height: 29px;
    }
    .close {
        display: inline-block;
        background-size: cover;
        background: transparent url(../images/icon-burger-close.svg) no-repeat 0 0;
        width: 45px;
        height: 29px;
    }
}

.slider_model .slick-slide img {
    width: 80% !important;
}

.slider_container {
  position: relative;
  margin-top: 57px;
  .slider_base {
    position: relative;

    .slider {
      position: relative;
    }
    .pastille {
      position: absolute;
      top: 0;
      right: 0;
    }
    .slide-text {
      background: url("../images/slider-mobile.svg") no-repeat;
      background-size: cover;
      padding: 22px 54px 23px 62px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      height: 141px;

      position: relative;
      top: -60px;

      .slide-titre {
        height: 100%;
      }
      .slide-content {
        display: none;
      }
    }

    .slick-slide {
      position: relative;

      img {
        max-height: 228px;
      }
    }

    .slick-arrow{
      z-index: 1;
      position: absolute;
      font-size: 40px;
      font-weight: 400;
      color: $pink;
      bottom: 110px;
      &.left{
        left: 22px;
      }

      &.right{
        right: 25px;
      }
    }
  }
}

.ariane {
  position: relative;
  padding-left: 27px;
  margin-bottom: 25px;
  display: none;
  i {
    color: $pink;
    font-size: 21px;
    line-height: 23.02px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  img {
    margin: 0 19px;
  }
  .accueil {
    color: $pink;
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    transition: all 0.5s;
    &:hover {
      color: #000;
    }
  }
  .lvl-1 {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-decoration: underline;
    transition: all 0.5s;
    &:hover {
      color: $pink;
    }
  }
  .lvl-2 {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    transition: all 0.5s;
    &:hover {
      color: $pink;
    }
  }
}

// *** Header *** //
#header-btn {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 0;

  .btn-retour-sp {
    background-color: #ffffff;
    color: #000000;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.39px;
    text-transform: uppercase;
    padding: 13px 11px;
    transition: all 0.5s;

    i {
      margin-right: 11px;
    }

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
}

#header-black {
  height: 70px;
  background-color: #000000;
  padding: 21px 5px;
  position: relative;

  .menu {
    font-size: 31px;
    display: inline-block;

    i {
      color: #eb399e;

      &.white {
        color: #fff;
      }
    }
  }
  .logo {
    display: inline-block;
    margin-left: 20px;
    img {
      margin-top: -15px;
    }
  }
  .menu-rapide {
    display: inline-flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    .cart {
      font-size: 22px;
      position: relative;
      margin: 5px 35px 0px 0px;
      i {
        transition: all 0.5s;
        color: #fff;
        &:hover {
          color: $pink;
          sub {
            background-color: $pink;
          }
        }
        sub {
          font-size: 0.6em;
          padding-left: 0.3em;
          padding-right: 0.3em; 
        }
      }

      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 41px;
        right: -21px;
        top: -6px;
        border: 1px solid #d8d8d8;
      }
    }

    .deco {
      font-size: 22px;
      position: relative;
      i {
        transition: all 0.5s;
        color: #fff;
        &:hover {
          color: $pink;
        }
      }

      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 41px;
        right: -21px;
        top: -6px;
        border: 1px solid #d8d8d8;
      }
    }
    .phone-menu-down {
      display: flex;
      margin-left: 38px;
      text-decoration: none;
      color: #fff;
      font-size: 15px;
      text-transform: uppercase;
      transition: all 0.5s;

      &:hover {
        color: $pink;
        .menu-down {
          color: $pink;
        }
      }
      .menu-down {
        color: #fff;
        font-size: 24px;
        margin: 0 21px 0 15px;
        transition: all 0.5s;
      }
    }
  }
}

#main_menu {
    width: 264px;
    box-shadow: 10px 10px 20px rgba(199, 205, 213, 0.55);
    background-color: #ffffff;
    margin-top: -7px;
    padding-top: 20px;
    display: none;
    position: absolute;
    z-index: 99;
    div {
        h3 {
            padding: 0;
            border: 0;
            a {
                padding: 0 32px 0 0;
                position: relative;
                color: $pink;
                font-size: 15px;
                font-weight: 700;
                letter-spacing: 0.45px;
                line-height: 55px;
                text-transform: uppercase;
                width: 200px;
                height: 55px;
                border-bottom: 1px solid #ccc;
                i, img {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 18px;
                    right: 0;
                }
                &.last {
                    border-bottom: 0;
                }
                &.current {
                    color: black;
                }
            }
        }
        h4 {
            a {
                &.current {
                    color: black;
                }
            }
        }
    }
    &.open {
        ul {
            margin: 0 0 60px 30px;
        }
    }
    ul {
        list-style: none;
        margin-bottom: 53px;
        margin-right: 30px;
    }
    &.fermer {
        div {
            a {
                width: 0px;
                .label {
                    display: none;
                }
                i, img {
                    left: 7px;
                    right: 10px;
                }
            }
            .toggle_lien {
                ul {
                    display: none;
                }
            }
        }
    }
}

#secondary_menu {
  width: 220px;
  background-color: #ffffff;
  position: absolute;
  right: 19px;
  margin-top: -9px;
  display: none;
  z-index: 99;

  ul {
    list-style: none;
    padding: 19px;
    li {
      a {
        width: 100%;
        padding-bottom: 23px;
        .titre {
          color: $pink;
          font-size: 16px;
          font-weight: 700;
          transition: all 1s;
        }
        .number {
          color: #000000;
          font-size: 17px;
          font-weight: 400;
          margin-top: 3px;
          transition: all 1s;
        }
        .infos-contact {
          color: #000000;
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
          margin-top: 5px;
          transition: all 1s;

          span {
            font-weight: 700;
          }
        }

        &:hover {
          .titre {
            color: #000;
          }
          .number {
            color: $pink;
          }
          .infos-contact {
            color: $pink;
          }
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 179px;
      height: 1px;
      background-color:  #c7cdd5;
      bottom: 28px;
      left: 19px;
      right: 19px;
    }

    &:after {
      content: "";
      position: absolute;
      width: 39px;
      height: 3px;
      background-color: #fff;
      bottom: 27px;
      left: 92px;
    }
  }
}

.profil {
  position: relative;
  text-align: center;
  padding-bottom: 25px;

  a {
    display: inline-flex;
    text-decoration: none;

    img {
      margin-right: 13px;
    }

    .infos {
      text-align: left;
      transition: all 0.5s;

      &:hover {
        .compte {
          text-decoration: underline;
        }
      }

      .id {
        color: #000000;
        font-size: 15px;
        font-weight: 700;
      }

      .compte {
        color: #000000;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.24px;
        transition: all .5s;
      }
    }
  }

  &:before {
    position: absolute;
    content: '';
    width: 190px;
    height: 1px;
    background-color: #000000;
    top: -18px;
    left: 42px;
  }
}

// *** index.php *** //
.image-index {
  background-image: url("../images/background-index-mobile.png");
  height: 150px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .logo-wrap {
    background-color: white;
    padding: 10px 22px 0;
    img {
      width: 134px;
      height: 23px;
    }
  }
}

.icheckbox {
  width: 21px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid rgba(99, 111, 121, 0.38);
  background-color: #ffffff;
  display: inline-block;
  margin: 3px 10px 0 9px;

  &.checked {
    background: url(../images/checkbox-checked.svg) no-repeat center center #fff;
  }

  + label {
    cursor: pointer;    
    line-height: 26px;
    vertical-align: top;
  }
}

label {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

textarea{
    height: 169px;
    resize: none;
    line-height: 1.2em;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    border: 2px solid #e7eced;
    background-color: #ffffff;
    color: #000000;
    font-size: 15px;
    &:focus{
        outline: none;
    }
}

.uppercase-label {
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin: 10px 0px;

  em {
    color: $pink;
  }

}

.content {
  text-align: center;

  h2 {
    color: #000000;
    font-weight: 400;
    font-size: 19px;
    position: relative;
    margin-top: 10px;
    margin-bottom: 0;

    span {
      color: $pink;
      font-weight: 700;
      line-height: 32px;
    }

    &:after {
      content: "";
      width: 146px;
      height: 2px;
      background-color: $pink;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -9px;
    }
  }

  h1 {
    color: #000000;
    font-size: 23px;
    font-weight: 700;
    line-height: 29px;
    margin-top: 57px;
  }

  form {
    margin-top: 52px;
    margin-bottom: 15px;

    .consent {
      > div {
        display: flex;
        align-items: center;
      }
      text-align: left;
      margin-top: -9px;
    }

    .same-address {
      margin-top: 22px;
      label {
        font-size: 12px;
        position: relative;
        padding: 0 25px 0 50px;
        cursor: pointer;

        .icheckbox {
          position: absolute !important;
          left: 18px;
          margin: 0;
        }
      }
    }


    .btn-connexion {
      margin-top: 37px;
    }
  }

  .lost-password {
    color: #000000;
    font-size: 12px;
    text-decoration: underline;
    font-style: italic;
    transition: all 0.5s;

    &:hover {
      color: $pink;
    }
  }

  .new-account {
    text-align: left;
    background-color: #000000;
    width: 100%;
    padding: 18px 20px;
    margin-top: 48px;
    transition: all 0.5s;

    &:hover {
      background-color: #ffffff;

      a {
        color: #000000;
      }
    }

    a {
      color: #ffffff;
      text-decoration: none;
      font-style: normal;
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
    }

    i {
      font-size: 25px;
      margin-right: 11px;

      &.pink {
        color: $pink;
      }

      &.right {
        font-size: 27px;
        position: absolute;
        right: 11px;
      }
    }

  }

  &.creation-compte {
    .input_text {
      width: 248px;

      &.large {
        width: 333px;
      }
    }

    .form-wrap {
      text-align: left;

      h1 {
        text-align: center;
      }
    }

    form {
      margin-top: 35px;

      .btn-connexion {
        text-align: center;
        margin-top: 20px;
      }
    }

    &:not(.creation-compte-form) {
      .bouton {
        &:after {
          right: 25px;
          transform: unset;
          left: unset;
        }
      }
    }

    .new-account {
      margin-top: 29px;
      padding: 18px 35px;
      position: relative;

      a {
        font-size: 19px;

        i {
          &.right {
            right: 26px;
          }
        }
      }
    }
  }

  &.creation-compte-form {
    h2 {
      margin-top: 17px;
    }

    h1 {
      margin-top: 32px;
    }

    h3 {
      color: $pink;
      font-size: 17px;
      font-weight: 700;
      letter-spacing: 0.68px;
      text-transform: uppercase;
      margin-left: 6px;
    }

    h4 {
      color: #000000;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.32px;
      position: relative;
      margin: 44px 6px 29px;

      &:after {
        content: '';
        position: absolute;
        width: 152px;
        height: 2px;
        background-color: #000;
        bottom: -6px;
        left: 0;
      }
    }

    form {
      margin-top: 52px;

      .form-part {
        margin-bottom: 60px;

        &.last {
          margin-bottom: 30px;
        }
      }

      .rows-wrap {
        background-color: rgba(199, 205, 213, 0.2);

        > :last-child {
          padding-bottom: 21px;
        }
      }

      .same-address {
        input {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
        }

        label {
          line-height: 1.1em;
        }
      }

      .input_text {
        padding: 0 19px;

        &.large {
        }
      }

      .btn-connexion {
        margin-top: 52px;
      }

      label {
        &.petit {
          font-size: 12px;
          line-height: 1.2em;
          margin-bottom: 21px;
          position: relative;
          padding: 0 43px 0 57px;
          width: 100%;
          cursor: pointer;

          .icheckbox {
            position: absolute !important;
            left: 18px;
            margin: 0;
          }

          span {
            color: $pink;
            font-weight: 700;
          }
        }
      }
    }

    .aside {
      margin: 57px 0;
    }
  }
}

.dashboard {
  padding-bottom: 55px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: 0;
      padding: 0;
    }
  }

  h1 {
    color: #000000;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  h2 {
    color: #000000;
    font-size: 25px;
    font-weight: 400;
    margin: -3px 0 34px;
  }

  h3 {
    color: #000000;
    font-size: 25px;
    font-weight: 400;
    padding: 34px 0;
    margin-bottom: 0;
    text-align: center;
    strong {
     font-weight: 700;
    }
  }

  .wrap-content {
    background-color: #fff;
    padding-bottom: 33px;
    margin-bottom: 39px;
    &.padding {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  .image {
    padding: 0 22px 25px;

    img {
      width: 100%;
    }
  }

  .fast-select {
    padding: 0 30px;
    line-height: 26px;
    margin-bottom: 24px;
  }

  .btn-wrap {
    text-align: center;
  }

  .toggle {
    .texte {
      margin-bottom: 41px;
      ul {
        flex: 1;
        margin-right: 15px;
          li {
            a {
              color: $pink;
              font-size: 16px;
              font-weight: 700;
              transition: all 0.5s;
              width: 100%;
              padding: 10px 0;
              &:hover {
                color: #000000;
              }
            }
            &:not(:last-child){
              border-bottom: 1px solid #cccccc;
            }
          }
      }
      .thumbnail {
        width: 200px;
        height: 100px;
        border: 1px solid #707070;
        position: relative;
        margin-top: 10px;
        overflow: hidden;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
      }
    }
  }

  &.creation {
    h2 {
      margin: 39px 0 8px;
    }
    h3 {
      padding: 38px 0;
    }
  }
}

// *** Filter - Product list page ***/
.products {
    .product-box:hover {
        box-shadow: 2px 2px 2px lightgrey;
        border: solid 2px lightgrey;
    }
}

// *** OrderForm ***/
.bg-secondary-hover:hover {
  color: white;
  background-color: $secondary !important;
  cursor: pointer;
}

.block-delivery-address, 
.block-delivery-contact, 
.block-expedition {
    background: #F1F1F1;
    padding: 15px;
}

.block-expedition {
    .form-check {
        padding: 0px;
    }
    legend {
        margin-top: 0px;
        padding-top: 0px;
    }
}

// *** Download *** //
.results {
  min-height: 400px;
}

.doc:hover {
    transform: scale(1.1);
    box-shadow: 2px 2px 5px 5px #f1f2f3;
}

// *** Footer *** //
#footer_bottom{
  padding: 13px 0 20px;
  text-align: center;
  p{
    margin: 0;
    color: rgba(64, 85, 116, 0.56);
    font-size: 11px;
    font-weight: 700;
    font-weight: 700;
    letter-spacing: 0.44px;
    text-transform: uppercase;
    a{
      color: inherit;
      transition: all 0.5s;

      &:hover {
        color: #000;
      }
    }
  }
}

// *** Cart & Order *** //
.row-product {
  border: 2px solid black;
  padding: 15px;
  margin: 15px 0 0 0;
}

// *** Contact Form *** //
.contact-form {
    .champs_gris {
        background-color: #e9ecef;
        cursor: not-allowed;
    }

    .map {
        position: absolute;
        overflow: hidden;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    .contact_co {
        margin-top: 420px;
    }

    .custom-select {
        cursor: pointer;
    }

    option {
        font-family: "Century Gothic", Sans-Serif;
    } 
}

// *** Form sur mesure *** //
.custom-select-wrapper {
    position: relative;
    user-select: none;
    width: 100%;
    .select {
        position: relative;
        display: flex;
        flex-direction: column;
        border-width: 1px 0;
        border-style: solid;
        &[disabled] {
          background: lightgrey;
          .arrow {
            display: none;
          }
        }
        .custom-select__trigger {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            border-width: 0 1px;
            border-style: solid;
            padding: 5px;
            min-height: 20px;
            .arrow {
                position: relative;
                height: 11px;
                width: 15px;
                &::before, &::after {
                    content: "";
                    position: absolute;
                    bottom: 0px;
                    width: 0.10rem;
                    height: 80%;
                }
                &::before {
                    left: -3px;
                    transform: rotate(-45deg);
                    background-color: $pink;
                }
                &::after {
                    left: 3px;
                    transform: rotate(45deg);
                    background-color: $pink;
                }
            }
        }
        &.open .arrow::before {
            left: -3px;
            transform: rotate(45deg);
        }
        &.open .arrow::after {
            left: 3px;
            transform: rotate(-45deg);
        }
    }
    .custom-options {
        position: absolute;
        display: none,;
        top: 100%;
        left: 0;
        right: 0;
        border: 1px solid;
        border-top: 0;
        background: #fff;
        transition: all 0.5s;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        z-index: 2;
        .choices {
          max-height: 240px;
          overflow-y: auto;
        }
        .custom-option {
            position: relative;
            padding: 5px;
            font-weight: 300;
            color: #3b3b3b;
            cursor: pointer;
            transition: all 0.5s;
            overflow: auto;
            &:hover {
                cursor: pointer;
                background-color: #f6f6f6;
            }
            &.selected {
                color: #ffffff;
                background-color: #305c91;
            }  
        }
    }
    .select.open .custom-options {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        display: block;
    }
}
// *** End form sur mesure *** //
.fast-select .algolia-autocomplete .aa-dropdown-menu { width: 300px; }

.hover-underline {
    &:hover {
        text-decoration: underline;
    }
}
// **** 3D *** //
#renderer_threeD {
    background-color: #E8E8E8;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}
#rendu {
    overflow: hidden;
    position: static;
    z-index:0;
    width: 100%;
    margin-top: 2px;
}

.picture {
    z-index: 15;
}


@import "member/_fromTablet";
@import "member/_fromPc";
@import "member/_fromPcXl";
@import "member/_mobileOnly";
@import "member/_tabletOnly";
@import "member/_pcOnly";