.Header {
  position: relative;
  z-index: 1000;
  display: flex;
  gap: 25px;
  .logo {
    width: 55px;
    height: 40px;
    background-image: url('../../../wcassets/icons/logo.png');
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: right center;
  }
  .header-body {
    flex: 1;
    display: flex;
    justify-content: space-between;
    .message {
      font-size: 20px;
      align-self: center;
      line-height: 1;
    }
    .navbar {
      all: unset;
      display: flex;
      overflow: hidden;
      display: flex;
      gap: 0.3em;
      padding: 0.2em;
      box-sizing: border-box;
      li {
        all: unset;
        cursor: pointer;
        position: relative;
        width: 50px;
        transition: 0.2s ease;
        height: auto;
        border-radius: 0.4em;
        &.undo:before {
          background-image: url(../../../wcassets/icons/icones/15.png);
        }
        &.redo:before {
          background-image: url(../../../wcassets/icons/icones/14.png);
        }
        &.new:before {
          background-image: url(../../../wcassets/icons/icones/15a.png);
        }
        &.copy:before {
          background-image: url(../../../wcassets/icons/icones/17.png);
        }
        &.screen:before {
          background-image: url(../../../wcassets/icons/icones/18.png);
        }
        &.save{
          &:before {
            background-image: url(../../../wcassets/icons/icones/161.png);
          }
          &:not(:focus-within){
            .SaveWork{
              transform: translate3d(0, 1em, 0);
              pointer-events: none;
              opacity: 0;
            }
          }
          .SaveWork{
            transition: .2s ease;
          }
        }
        &.open:before {
          background-image: url(../../../wcassets/icons/icones/285.png);
        }
        &.logout:before {
          background-image: url(../../../wcassets/icons/icones/19.png);
        }
        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.1);
          &:before {
            transform: scale(1.1);
          }
        }
        &:active:before {
          transition-duration: 0s;
          transform: scale(0.95);
        }
        &:before {
          content: "";
          display: flex;
          position: absolute;
          inset: 0;
          background-size: 45%;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .center-nav {
    all: unset;
    display: flex;
    background-color: white;
    border-radius: 0 0 10px 10px;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    box-shadow: 1px 1px 1px 1px #a4a4a4;
    height: 35px;
    top: 100%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    padding: 0 0.5em;
    > li {
      all: unset;
      width: 35px;
      height: 35px;
      position: relative;
      all: unset;
      position: relative;
      width: 50px;
      transition: 0.2s ease;
      height: auto;
      border-radius: 0.4em;
      &.inactive:before{
        pointer-events: none;
        opacity: .5;
      }
      &.up:before {
        background-image: url(../../../wcassets/icons/icones/20.png);
      }
      &.size:before {
        background-image: url(../../../wcassets/icons/icones/21.png);
      }
      &.view:before{
        background-image: url(../../../wcassets/icons/icones/22.png);
      }
      &.design:before{
        background-image: url(../../../wcassets/icons/icones/24.png);
      }
      &.setting:before{
        background-image: url(../../../wcassets/icons/icones/29.png);
      }
      &.screenshot:before{
        background-image: url(../../../wcassets/icons/icones/25.png);
      }
      &.measure:before{
        background-image: url(../../../wcassets/icons/icones/mesure.png);
      }
      
      &:hover {
        &:before {
          transform: scale(1.1);
        }
      }
      &:after {
        content: "";
        transition: 0.2s ease;
        position: absolute;
        bottom: 0;
        background-color: #cf128c;
        height: 3px;
        right: 0;
        left: auto;
        width: 0;
      }
      &:hover:after {
        left: 0;
        right: auto;
        width: 100%;
      }
      &:before {
        content: "";
        cursor: pointer;
        display: flex;
        position: absolute;
        inset: 0;
        background-size: 45%;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  .navbar .nav-item:hover {
    border-top: 3px solid;
  }
}
