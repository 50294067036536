.Design{
  .d-options{
    display: flex;
    flex-direction: column;
    label{
      display: flex;
      padding: 0.5em 1em;
      padding-left: .5em;
      gap: .5em;
      align-items: center;
      cursor: pointer;
      span{
        white-space: nowrap;
        font-size: .85em;
      }
    }
  }
}