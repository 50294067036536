@import "../../../../wcassets/styles/colors.scss";

.Loading{
  font-size: .75em ;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #555;
  &:before{
    content: '';
    width: 1.5em;
    height: 1.5em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    animation: data 1.5s infinite linear;
    background-image: url(../../../../wcassets/icons/spinner.svg);
  }
}

@keyframes data {
  100% { transform: rotate(360deg); }
}