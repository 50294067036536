.Settings{
  .s-options{
    div{
      padding: 0.5em 1em;
      cursor: pointer;
      span{
        font-size: .85em;
        white-space: nowrap;
      }
    }
  }
}