.Dimensions{
  .d-inputs{
    padding: 0.7em 1em;
    display: flex;
    flex-direction: column;
    gap: .5em;
    min-width: 180px;
    label{
      position: relative;
      display: flex;
      flex-direction: column;
      span{
        font-size: .8em;
        text-align: left;
        line-height: 1.5;
        &:after{
          content: var(--par, '');
          font-size: .7em;
          margin-left: .5em;
          position: relative;
          top: -.15em;
        }
      }
      input{
        border: none;
        padding: 0.8em 1em;
        border-radius: 5px;
        outline: none;
        &.error{
          color: red;
        }
      }
    }
  }
  &:not(.d-active) {
    pointer-events: none;
    *{
      opacity: .8;
    }
  }
}