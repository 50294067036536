.ModuleList{
  display: flex;
  padding: .5em;
  gap: .5em;
  justify-content: center;
  flex-wrap: wrap;
  .module-item{
    display: flex;
    width: 30%;
    border-radius: .3em;
    box-shadow: 0 0 2px #333;
    position: relative;
    cursor: pointer;
    background-color: #fff;
    transition: .2s ease;
    &:before{
      content: '';
      padding-top: 100%;
    }
    .ci-container{
      position: absolute;
      inset: 0;
      display: flex;
      flex-direction: column;
      &:before{
        content: '';
        flex: 1;
        background-image: var(--image);
        background-size: auto 90%;
        background-repeat: no-repeat;
        background-position: center;
      }
      span{
        font-size: .65em;
        align-self: center;
        line-height: 1;
        word-wrap: break-word;
        max-width: 90%;
        text-align: center;
        margin-bottom: .2em;
      }
    }
    &.loading{
      .ci-container:after{
        content: '';
        position: absolute;
        inset: 0;
        background-color: rgba(#fff, .8);
        background-image: url(../../../../../wcassets/icons/load.svg);
        border-radius: 1em;
        background-size: 50%;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &.selected{
      background-color: #fad4ed; // E788C5
      box-shadow: 0 0 0 2px #E788C5;
      transform: scale(1.03);
    }
  }
}