.SidePane{
  position: absolute;
  top: 0px;
  width: 300px;
  height: 400px;
  z-index: 3;
  border-radius: 5px;
  overflow: hidden;
  left: 120%;
  background-color: rgba(#fff, .5);
  backdrop-filter: blur(5px);
  box-shadow: 1px 1px 2px #A4A4A4;
  display: flex;
  flex-direction: column;
  outline: none;
  .pane-header{
    background-color: #424242;
    padding: 10px;
    color: white;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
  }
  .pane-content{
    flex: 1 1 auto;
    overflow-y: auto; 
  }
}