.Popup {
  z-index: 100;
  position: absolute;
  height: 0;
  top: 100%;
  left: 0;
  right: 0;
  user-select: none;
  &.global{
    z-index: 1001;
    margin-top: 0;
  }

  .message {
    font-size: .8em;
    transform: translate3d(0, 0, 0);
    position: absolute;
    left: 7px;
    padding: .5em 1.5em;
    background: rgba(#fff, .8);
    box-shadow: 0 0 1px #333;
    border-radius: .5em .5em 0 0;
    min-width: 100px;
    max-width: 250px;
    text-align: center;
    transition: transform .2s ease-in-out;
    pointer-events: none;
    display: flex;
    gap: .5em;


    &.active {
      transform: translate3d(0, -100%, 0);

      &:not(:first-child) {
        transform: translate3d(0, 0, 0);
      }
    }

    &:before {
      content: '';
      width: 1em;
      // height: 1em;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &.error {
      color: #e34234;

      &::before {
        width: 2em;
        background-size: cover;
        background-position: center;
        background-image: url(../../../../../wcassets/icons/warning.svg);
      }
    }

    &.success {
      flex-direction: row-reverse;

      &::before {
        width: 2em;
        background-position: 50% 40%;
        background-image: url(../../../../../wcassets/icons/check.svg)
      }
    }

    &.info {
      color: #73C2FB;

      &::before {
        content: '';
        background-image: url(../../../../../wcassets/icons/info-popup.svg);
      }
    }


    &.waiting {
      flex-direction: row-reverse;

      &::before {
        width: 2em;
        background-position: 50% 40%;
        background-image: url(../../../../../wcassets/icons/load.svg);
      }
    }

    &.info {
      flex-direction: row-reverse;

      &::before {
        width: 2em;
        background-size: contain;
        background-position: center;
        background-image: url(../../../../../wcassets/icons/info.svg);
      }
    }
  }
}