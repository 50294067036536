
.DimensionInfo{
  user-select: none;
  pointer-events: none;
  color: #444;
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: .5em;
  top: 3.5em;
  right: 1em;
  background: rgba(#fff, .5);
  padding: 1em 1.7em;
  border-radius: .5em;
  backdrop-filter: blur(5px);
  transition: opacity .3s ease;
  z-index: 2;
  &.inactive{
    opacity: 0;
  }
  .di-item{
    font-size: .8em;
    .di-value{
      font-size: 1.1em;
    }
  }
}
